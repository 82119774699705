import * as ACTIONS from "@iso/redux/languages/actionTypes";

const initialState = {
  languages: [],
  langsMap: {},
  defaultLang: "",
};

function LanguageReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_LANGUAGES_SUCCESS: {
      return {
        ...state,
        ...{
          languages: payload?.list?.sort((a, b) => b.default - a.default).map(lang => lang.locale),
          langsMap: generateLangsMap(payload?.list),
          defaultLang: payload?.list?.find(item => item.default === true).locale,
        },
      };
    }

    default:
      return state;
  }
}

const generateLangsMap = langs => {
  let response = {};
  langs.forEach(item => {
    response = {
      ...response,
      [item.id]: item.slug,
    };
  });
  return response;
};

export default LanguageReducer;
