import * as ACTIONS from "@iso/redux/articles/actionTypes";

const initialState = {
  categories: {
    list: [],
    total: 0,
  },
  articles: {
    list: [],
    total: 0,
  },
};

function articlesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_ARTICLES_SUCCESS: {
      return {
        ...state,
        ...{ articles: payload },
      };
    }
    case ACTIONS.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        ...{ categories: payload },
      };
    }

    default:
      return state;
  }
}

export default articlesReducer;
