export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";

export const GET_ARTICLES_REQUEST = "GET_ARTICLES_REQUEST";
export const GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS";

export const SHOW_ARTICLE_REQUEST = "SHOW_ARTICLE_REQUEST";
export const SHOW_ARTICLE_SUCCESS = "SHOW_ARTICLE_SUCCESS";

export const POST_ARTICLE_REQUEST = "POST_ARTICLE_REQUEST";
export const POST_ARTICLE_SUCCESS = "POST_ARTICLE_SUCCESS";

export const PUT_ARTICLE_REQUEST = "PUT_ARTICLE_REQUEST";
export const PUT_ARTICLE_SUCCESS = "PUT_ARTICLE_SUCCESS";

export const DELETE_ARTICLE_REQUEST = "DELETE_ARTICLE_REQUEST";
export const DELETE_ARTICLE_SUCCESS = "DELETE_ARTICLE_SUCCESS";

export const GET_ARTICLES_ACTIVITES_REQUEST = "GET_ARTICLES_ACTIVITES_REQUEST";
export const GET_ARTICLES_ACTIVITES_SUCCESS = "GET_ARTICLES_ACTIVITES_SUCCESS";
