import * as ACTIONS from "@iso/redux/priceRanges/actionTypes";

const initialState = {
  priceRanges: {
    list: [],
    total: 0,
  },
};

function priceRangesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_PRICE_RANGES_SUCCESS: {
      return {
        ...state,
        ...{ priceRanges: payload },
      };
    }
    default:
      return state;
  }
}

export default priceRangesReducer;
