// const testRoutes = [
//   { path: "" },
//   { path: "my-profile" },
//   { path: "leafletmap" },
//   { path: "allFormComponent" },
//   { path: "InputField" },
//   { path: "editor" },
//   { path: "stepperForms" },
//   { path: "FormsWithValidation" },
//   { path: "progress" },
//   { path: "button" },
//   { path: "tab" },
//   { path: "autocomplete" },
//   { path: "checkbox" },
//   { path: "radiobox" },
//   { path: "selectbox" },
//   { path: "transfer" },
//   { path: "alert" },
//   { path: "modal" },
//   { path: "message" },
//   { path: "breadcrumb" },
//   { path: "dropdown" },
//   { path: "op_tag" },
//   { path: "op_timeline" },
//   { path: "uppy" },
//   { path: "dropzone" },
// ];

const options = [
  {
    key: "cms",
    label: "sidebar.cms",
    leftIcon: "ion-ios-cog",
    children: [
      {
        key: "menu",
        label: "sidebar.menu",
      },
      {
        key: "pages",
        label: "sidebar.pages",
      },
      {
        key: "settings",
        label: "sidebar.settings",
      },
      {
        key: "translates",
        label: "sidebar.translates",
      },
      {
        key: "languages",
        label: "sidebar.languages",
      },
    ],
  },
  {
    key: "articles-list",
    label: "sidebar.articles",
    leftIcon: "ion-ios-pint-outline",
    children: [
      {
        key: "articles",
        label: "sidebar.articles",
      },
    ],
  },
  {
    key: "sections",
    label: "sidebar.sections",
    eventKey: "sidebar.sections",
    leftIcon: "ion-ios-list",
    children: [
      {
        key: "sections-reviews",
        label: "sidebar.section_reviews",
        eventKey: "sidebar.section_reviews",
      },
      {
        key: "sections-offers",
        label: "sidebar.section_offers",
        eventKey: "sidebar.section_offers",
      },
    ],
  },
  {
    key: "newsletters",
    label: "sidebar.newsletters",
    eventKey: "sidebar.newsletters",
    leftIcon: "ion-ios-email",
  },
  {
    key: "feedbacks",
    label: "sidebar.feedback",
    eventKey: "sidebar.feedback",
    leftIcon: "ion-android-contacts",
  },
  {
    key: "price-ranges",
    label: "sidebar.price_ranges",
    eventKey: "sidebar.price_ranges",
    leftIcon: "ion-cash",
  },
  {
    key: "reservations",
    label: "sidebar.reservations",
    eventKey: "sidebar.reservations",
    leftIcon: "ion-ios-clock",
  },
  // {
  //   key: "test",
  //   label: "sidebar.test",
  //   leftIcon: "ion-bag",
  //   children: testRoutes.map((q) => ({
  //     key: q.path,
  //     label: "sidebar." + q.path,
  //   })),
  // },
];
export default options;
